<template>
  <div class="propertydashboard__body">
    <div class="bodyheader">
      <router-link to="/" class="mobile-back">
        <i class="fas fa-chevron-left" />
      </router-link>
      <h4>{{ propertyData.fullAddress }}</h4>
      <div class="bodyheader__menu">
        <a href="#" class="bodyheader__menuitem active">propostas</a>
        <a
          href="#"
          class="bodyheader__menuitem bodyheader__menuitem--mobile active"
        >
          <i class="fas fa-clipboard-check" />
        </a>
      </div>
    </div>
    <div v-if="!offerCreated && !isLoading" class="bodycontent">
      <h2 class="bodycontent__header bodycontent__header--desktop">
        Criar nova proposta
      </h2>
      <h2 class="bodycontent__header bodycontent__header--mobile">
        criar proposta
      </h2>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onClickContinue)">
          <template v-if="propertyData.businessType === 'buy/rent'">
            <div class="chooser-field">
              <label class="chooser-field__label">TIPO DE PROPOSTA</label>
              <ChooserField
                class="chooser"
                v-model="typeBid"
                :options="[
                  { label: 'aluguel', value: 'rent' },
                  { label: 'compra', value: 'buy' }
                ]"
              />
            </div>
          </template>
          <phone-field
            v-model="userPhone"
            :placeholder="'Número de celular'"
            rules="required"
            name="telefone celular"
            class="proposalfield"
          />
          <text-field
            v-model="userName"
            :autocomplete="'name'"
            rules="required"
            type="text"
            label="Nome completo"
            :placeholder="'nome completo'"
            name="nome completo"
            class="proposalfield"
          />
          <text-field
            v-model="userEmail"
            rules="required"
            label="E-mail"
            :autocomplete="'email'"
            :placeholder="'seu email'"
            name="e-mail"
            class="proposalfield"
            type="email"
          />
          <currency-field
            v-model="rentValue"
            rules="required"
            label="Valor do Aluguel"
            name="valor"
            :placeholder="'R$ 1000,00'"
            class="proposalfield"
          />
          <text-field
            v-model="userMessage"
            label="Detalhes"
            name="detalhes"
            :autocomplete="'off'"
            :placeholder="'ex: colocar armários'"
            type="text"
            class="proposalfield text-message"
          />
          <text-field
            v-model="rentalTime"
            label="Tempo de locação (em meses)"
            name="tempo de locação"
            :autocomplete="'off'"
            :placeholder="'ex: 12'"
            type="number"
            class="proposalfield"
          />
          <text-field
            v-model="warrantyName"
            label="Garantia"
            name="garantia"
            :autocomplete="'off'"
            :placeholder="'ex: Fiador, CredPago, Porto Seguro...'"
            type="text"
            class="proposalfield"
          />
          <div>
            <button-component
              class="bodycontent__footerconfirm"
              design-type="default"
              block
              font-size="16px"
              type="submit"
            >
              Continuar
            </button-component>
          </div>
        </form>
      </ValidationObserver>
    </div>

    <div v-else-if="isLoading" class="bodycontent">
      <h2 class="bodycontent__header bodycontent__header--desktop">
        Criar nova proposta
      </h2>
      <h2 class="bodycontent__header bodycontent__header--mobile">
        criar proposta
      </h2>
      <div class="loading" />
    </div>

    <div v-if="offerCreated" class="bodycontent">
      <i class="check fas fa-check-circle" />
      <h2 class="bodycontent__title bodycontent__header--desktop">
        Proposta enviada
      </h2>
      <h2 class="bodycontent__header bodycontent__header--mobile">
        proposta enviada
      </h2>
      <p class="bodycontent__subtitle">
        Você enviou uma proposta para esse imóvel no valor de R$<b
          >{{ rentValue }},00</b
        >.
      </p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import * as propertyAPI from '@/api/property'
import * as financialAPI from '@/api/financial'
import * as visitAPI from '@/api/visit'
import * as userAPI from '@/api/user'
import * as bidAPI from '@/api/bid'

export default {
  name: 'BidCreate',
  data () {
    return {
      isLoading: false,
      disableFields: false,
      disablePhoneField: false,
      propertyData: {},
      financialData: {},
      userName: '',
      userEmail: '',
      userPhone: '',
      rentValue: '',
      userMessage: '',
      warrantyName: '',
      rentalTime: 0,
      typeBid: 'rent',
      offerCreated: false
    }
  },
  computed: {
    ...mapGetters({
      userGetter: 'user/userGetter',
      configGetter: 'company/configGetter'
    })
  },
  async created () {
    this.isLoading = true
    this.disableFields = true

    if (this.$route.params.visitId) {
      this.disablePhoneField = true
      await visitAPI
        .searchVisitClient({
          companyId:
            this.userGetter.user.companyId !== null
              ? this.userGetter.user.companyId
              : 1,
          visitId: this.$route.params.visitId
        })
        .then(async response => {
          if (response && response.data) {
            userAPI
              .getUserByIdClient({
                userId: response.data.clientIdentifier
              })
              .then(userResponse => {
                if (userResponse && userResponse.data) {
                  this.userName = userResponse.data.name
                  this.userEmail = userResponse.data.email
                  this.userPhone = userResponse.data.phone
                }
              })
          }
        })
    } else {
      this.userPhone = this.userGetter.user.phone
    }

    await propertyAPI
      .byIdClient({
        id: this.$route.params.propertyId
      })
      .then(async response => {
        if (response && response.data) {
          this.propertyData = response.data
          if (this.propertyData.businessType === 'buy') this.typeBid = 'buy'
        }

        await financialAPI
          .showClient({
            id: this.$route.params.propertyId
          })
          .then(response => {
            if (response && response.data) {
              this.rentValue = `${response.data.rental}00`
            }
          })
      })
      .finally(() => {
        this.isLoading = false
      })
  },
  methods: {
    onClickContinue () {
      const payload = {
        propertyId: this.$route.params.propertyId,
        creatorIdentifier: this.userGetter.user.identifier,
        client: {
          name: this.userName,
          phone: '+' + this.userPhone.replace(/[^\d]+/g, ''),
          email: this.userEmail
        },
        status: 'Aguardando proprietário',
        details: this.userMessage,
        value: this.rentValue,
        visitId: this.$route.params.visitId ? this.$route.params.visitId : null,
        warrantyName: this.warrantyName,
        rentalTime: this.rentalTime
      }

      bidAPI.createBidClient(payload).then(response => {
        if (response && response.data) {
          this.offerCreated = true
        }
      })
    }
  }
}
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.propertydashboard__body {
  @include respond-to(phone-only) {
    border-radius: 0;
    box-shadow: none;
    height: auto;

    .bodyheader {
      border-radius: 0;

      .mobile-back {
        display: block;
        position: absolute;
        padding-top: 38px;
        padding-left: 15px;
        color: #fff;
        font-size: 22px;
      }

      h4 {
        padding-right: 35px;
        padding-left: 35px;
        height: 98px;
        text-align: center;
        line-height: 17px;
        letter-spacing: -0.02em;
        color: #fff;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
      }

      &__menu {
        width: 100%;

        a {
          display: none;
        }

        a.bodyheader__menuitem--mobile {
          display: flex;
          border-bottom: 4px solid rgba(255, 255, 255, 0);
        }

        a.bodyheader__menuitem--mobile.active,
        a.bodyheader__menuitem--mobile:hover {
          transition: border-bottom 0.3s ease-in-out;
          border-bottom: 4px solid #fff;
          background: none;
        }
      }
    }

    .bodycontent {
      padding-top: 20px;

      h2.bodycontent__header--desktop {
        display: none;
      }

      h2.bodycontent__header--mobile {
        display: block;
        transform: none;
        margin-bottom: 20px;
        margin-left: 0;
        border-bottom: 0;
        padding-left: 15px;
        text-align: left;
        line-height: normal;
        letter-spacing: -0.02em;
        color: #2d5aa0;
        font-size: 24px;
        font-weight: bold;
        font-style: normal;
      }
    }
  }
  border-radius: 10px;
  box-shadow: 8px 8px 55px rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 0;
  padding-bottom: 50px;
  min-height: calc(100vh - 160px);

  .bodyheader {
    border-radius: 10px 10px 0 0;
    background: #00448a;
    width: 100%;

    .mobile-back {
      display: none;
    }

    h4 {
      box-shadow: 0 10px 9px -5px rgba(0, 0, 0, 0.24);
      padding: 32px 0;
      text-align: center;
      line-height: 28px;
      letter-spacing: -0.02em;
      color: #fff;
      font-size: 26px;
      font-weight: normal;
      font-style: normal;
    }

    &__menu {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin: auto;
      width: 60%;
      height: 53px;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 0.3s ease-in-out;
        width: 20%;
        height: 53px;
        text-align: center;
        line-height: 19px;
        letter-spacing: -0.02em;
        color: #fff;
        font-size: 18px;
        font-weight: 300;
        font-style: normal;
      }

      a.bodyheader__menuitem--mobile {
        display: none;
      }

      a:hover,
      a.active {
        transition: background-color 0.3s ease-in-out;
        background: rgba(0, 0, 0, 0.3);
      }
    }
  }

  .bodycontent {
    padding-top: 56px;

    .check {
      display: block;
      margin-top: 120px;
      margin-bottom: 20px;
      text-align: center;
      font-size: 140px;
    }

    &__header {
      display: inline-block;
      transform: translateX(-50%);
      margin-bottom: 35px;
      margin-left: 50%;
      border-bottom: 2px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 10px;
      text-align: center;
      line-height: normal;
      letter-spacing: -0.04em;
      color: #333;
      font-size: 24px;
      font-weight: bold;
      font-style: normal;
    }

    h2.bodycontent__header--mobile {
      display: none;
    }

    &__title {
      margin: 0;
      padding: 10px 0;
      text-align: center;
      line-height: normal;
      letter-spacing: -0.03em;
      color: #444;
      font-size: 28px;
      font-weight: 700;
    }

    &__subtitle {
      text-align: center;
      font-size: 16px;
    }

    &__footerconfirm {
      @include respond-to(phone-only) {
        width: 50%;
      }
      margin: 30px auto;
      border-radius: 40px;
      width: 20%;
    }
  }
}

.propertydashboard__body::-webkit-scrollbar {
  background: #fff;
  width: 0.5em !important;
  height: 0.5em !important;
}

.propertydashboard__body::-webkit-scrollbar {
  width: 0 !important;
}

.propertydashboard__body::-webkit-scrollbar-thumb {
  border-radius: 3px !important;
  background-color: rgba(45, 90, 160, 0.8) !important;
}

.proposalfield {
  margin: auto;
  width: 50%;
}

.proposalfield {
  margin-top: $form-input-margin-bottom;
}

@include respond-to(phone-only) {
  .proposalfield {
    margin: auto;
    width: 90%;
  }
}
</style>
