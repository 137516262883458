import * as http from './http'

export const show = function (data, config) {
  const URL = `/properties/${data.id}/financial`

  return http.get(URL, config)
}

export const showClient = function (data, config) {
  const URL = `/properties-client/${data.id}/financial`

  return http.get(URL, config)
}

export const create = function (data, config) {
  const URL = `/properties/${data.propertyId}/financial`
  let body = {}

  if (Object.keys(data).length) {
    delete data.propertyId
    body = data
  }

  return http.post(URL, body, config)
}

export const update = function (data, config) {
  const URL = `/properties/${data.propertyId}/financial`
  let body = {}

  if (Object.keys(data).length) {
    delete data.propertyId
    body = data
  }

  return http.put(URL, body, config)
}
