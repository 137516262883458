import * as http from './http'

export const list = function (data, config) {
  let URL = `/companies/${data.companyId}/visits`

  if (Object.keys(data).length > 1) {
    delete data.companyId
    URL += '?' + new URLSearchParams(data).toString()
  }

  return http.get(URL, config)
}

export const searchVisitClient = function (data, config) {
  let URL = `/companies/${data.companyId}/visits-client/${data.visitId}`

  if (Object.keys(data).length > 1) {
    delete data.companyId
    delete data.visitId
    URL += '?' + new URLSearchParams(data).toString()
  }

  return http.get(URL, config)
}

export const searchVisit = function (data, config) {
  let URL = `/companies/${data.companyId}/visits/${data.visitId}`

  if (Object.keys(data).length > 1) {
    delete data.companyId
    delete data.visitId
    URL += '?' + new URLSearchParams(data).toString()
  }

  return http.get(URL, config)
}

export const getBrokerVisits = function (data, config, date) {
  const URL = `/companies/${data.companyId}/brokers/${data.brokerId}/visits?date==${date}`

  return http.get(URL, config)
}

export const update = function (data, config) {
  const url = `/companies/${data.companyId}/visits/${data.visitId}`

  return http.put(url, data.body, config)
}

export const getVisitById = function (data, config) {
  const URL = `/companies/${data.companyId}/visits/${data.visitId}`

  return http.get(URL, config)
}

export const updateVisitClient = function (data, config) {
  const URL = `/companies/${data.companyId}/visits-client/${data.visitId}`
  let body = {}

  if (Object.keys(data).length) {
    delete data.companyId
    delete data.visitId
    body = data
  }

  return http.put(URL, body, config)
}

export const createVisit = function (data, config) {
  const URL = '/visits'
  let body = {}

  if (Object.keys(data).length) {
    delete data.companyId
    body = data
  }

  return http.post(URL, body, config)
}

export const getVisit = function (data, config) {
  let URL = `visits/${data.visitId}`
  if (Object.keys(data).length > 1) {
    delete data.companyId
    URL += '?' + new URLSearchParams(data).toString()
  }
  return http.get(URL, config)
}

export const updateVisit = function (data, config) {
  const URL = `visits/${data.visitId}`
  return http.put(URL, data, config)
}

export const getVisitHistory = function (data, config) {
  let URL = `visit-history/${data.visitId}`
  if (Object.keys(data).length > 1) {
    delete data.companyId
    URL += '?' + new URLSearchParams(data).toString()
  }
  return http.get(URL, config)
}

export const visitCheckIn = function (data, config) {
  const URL = `visits/${data.visitId}/checkin`
  return http.put(URL, data, config)
}

export const visitCheckOut = function (data, config) {
  const URL = `visits/${data.visitId}/checkout`
  return http.put(URL, data, config)
}

export const visitCollectedKey = function (data, config) {
  const URL = `visits/${data.visitId}/collected-key`
  return http.put(URL, data, config)
}

export const visitReturnKey = function (data, config) {
  const URL = `visits/${data.visitId}/returned-key`
  return http.put(URL, data, config)
}
